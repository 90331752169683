/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import 'bedag-style';

body {
  background-color: #f9f9f9;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: var(--bedag-violet-100);
  text-decoration: none;
}

.app-sidebar-expanded {
  // marker class
}

.fa-1\.5x {
  font-size: 1.5rem;
}

.fa-1x {
  font-size: 1rem;
}

.menu-icon {
  width: 1.5rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  color: var(--icon-color);
}

.navigation-icon {
  color: var(--icon-color);
}

button.dropdown-item {
  border-radius: 0;
}

:root {
  --app-header-height: 4rem;
  --app-functionbar-height: 2.5rem;
  --app-sidebar-width: 4rem;
  --app-sidebar-width-expanded: 21rem;

  --border-color: rgba(50, 50, 50, 0.5);

  --icon-color: #5a5a5a;
}

.pagination-layout--center {
  display: flex;
  justify-content: center;
}

ngb-pagination {
  .page-item {
    .page-link {
      color: $gray-900;

      &:hover {
        color: $gray-700;
      }
    }
  }

  .page-item.active {
    .page-link {
      background-color: $bedag-green-100;
      border-color: $bedag-green-100;

      &:hover {
        color: $white;
      }
    }
  }
}

ngb-datepicker {
  .ngb-dp-navigation-chevron {
    color: $bedag-green-100;
  }

  .ngb-dp-weekday {
    color: $bedag-green-100
  }

  .bg-primary {
    background-color: $bedag-green-100 !important;
  }

  .form-select:focus {
    border-color: $bedag-green-100;
    box-shadow: 0 0 0 2px $bedag-green-80;
  }
}

.list-table--fixed {
  table-layout: fixed;
}

.navigation-table__row:hover {
  cursor: pointer;
}

.page--functionbar {
  padding-top: 2rem;
}

.ng-touched.ng-invalid {
  @extend .is-invalid;
}

.tox-tinymce {
  border: none !important;
}

.sortable:hover {
  cursor: pointer;
}

.sortable__index {
  font-weight: lighter;
  font-size: 0.8rem;
  margin-left: 10px;
}

.sortable__undefined:after,
.sortable__asc:after,
.sortable__desc:after {
  font-family: "Font Awesome 6 Free", "sans-serif";
  display: inline-block;
  padding-left: 3px;
  font-weight: 900;
  font-size: 0.8rem;
}

.sortable__undefined:after {
  content: "\f0dc"
}

.sortable__asc:after {
  content: "\f0de";
}

.sortable__desc:after {
  content: "\f0dd";
}

.page-filter {
  display: block;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s, opacity 0.2s, padding-bottom 0.2s;
}

.page-filter--expand {
  @extend .page-filter;
  padding-bottom: 2em;
  max-height: 500px;
  overflow: visible;
  opacity: 1;
}

.loading-ellipsis::after {
  content: '   ';
  white-space: pre;
  animation: loading-ellipsis steps(4,end) 3s infinite;
}

@keyframes loading-ellipsis {
  0% {
    content: '   ';
  }
  33% {
    content: '.  ';
  }
  66% {
    content: '.. ';
  }
  100% {
    content: '...';
  }
}

.nav {
  --bs-nav-link-hover-color: var(--bedag-green-100);
}

.form-check-input:focus {
  border-color: var(--bedag-green-80);
  box-shadow: 0 0 0 0.25rem var(--bedag-green-40);
}

.form-check-input:checked {
  background-color: var(--bedag-green-100);
  border-color: var(--bedag-green-100);
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--bedag-green-100);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px var(--bedag-green-40);
}
