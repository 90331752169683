$bedag-green-100: #96cd6e;
$bedag-green-80: #abd78b;
$bedag-green-60: #c0e1a8;
$bedag-green-40: #d5ebc5;
$bedag-green-20: #eaf5e2;

$bedag-dark-violet-100: #2d1946;
$bedag-dark-violet-80: #57476b;
$bedag-dark-violet-60: #817590;
$bedag-dark-violet-40: #aba3b5;
$bedag-dark-violet-20: #d5d1da;

$bedag-violet-100: #6e50be;
$bedag-violet-80: #8b73cb;
$bedag-violet-60: #a896d8;
$bedag-violet-40: #c5b9e5;
$bedag-violet-20: #e2dcf2;

$bedag-turquoise-100: #50696e;
$bedag-turquoise-80: #73878b;
$bedag-turquoise-60: #96a5a8;
$bedag-turquoise-40: #b9c3c5;
$bedag-turquoise-20: #dce1e2;

$bedag-orange: #ff865f;

$bedag-gray: #d2d2d2;

// @formatter:off
$bedag-colors: (
  // green
  'bedag-green-100': $bedag-green-100,
  'bedag-green-80' : $bedag-green-80,
  'bedag-green-60' : $bedag-green-60,
  'bedag-green-40' : $bedag-green-40,
  'bedag-green-20' : $bedag-green-20,
  // dark-violet
  'bedag-dark-violet-100': $bedag-dark-violet-100,
  'bedag-dark-violet-80' : $bedag-dark-violet-80,
  'bedag-dark-violet-60' : $bedag-dark-violet-60,
  'bedag-dark-violet-40' : $bedag-dark-violet-40,
  'bedag-dark-violet-20' : $bedag-dark-violet-20,
  // violet
  'bedag-violet-100': $bedag-violet-100,
  'bedag-violet-80' : $bedag-violet-80,
  'bedag-violet-60' : $bedag-violet-60,
  'bedag-violet-40' : $bedag-violet-40,
  'bedag-violet-20' : $bedag-violet-20,
  // turquoise
  'bedag-turquoise-100': $bedag-turquoise-100,
  'bedag-turquoise-80' : $bedag-turquoise-80,
  'bedag-turquoise-60' : $bedag-turquoise-60,
  'bedag-turquoise-40' : $bedag-turquoise-40,
  'bedag-turquoise-20' : $bedag-turquoise-20,
  // orange
  'bedag-orange': $bedag-orange,
  // gray
  'bedag-gray': $bedag-gray,
);
// @formatter:on

:root {
  @each $name, $color in $bedag-colors {
    --#{$name}: #{$color};
  }
}

@each $name, $color in $bedag-colors {
  .btn-#{$name} {
    @include button-variant($color, $color);
  }
  .btn-outline-#{$name} {
    @include button-outline-variant($color);
  }
  .#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
}

.form-control {

  color: inherit;

  &:focus {
    border-color: $bedag-green-100;
    box-shadow: 0 0 0 2px $bedag-green-80;
  }

  &:disabled {
    @extend .form-control-plaintext;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $bedag-green-100;
  background-color: $bedag-green-100;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(168, 179, 10, 0.5);
}

.medium-checkbox {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.medium-switch {
  width: 3rem !important;
  height: 1.5rem !important;
}

.small-checkbox {
  width: 1rem !important;
}

.btn-simple-primary, .btn-simple-danger, .btn-link-danger {
  border: none;
  background: none;
  color: var(--icon-color);
  padding: 0;
  margin-left: 0.75rem;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.btn-simple-primary {
  &:hover {
    color: var(--bedag-green-100)
  }
}

.btn-simple-danger {
  &:hover {
    color: red
  }
}

.btn-link-danger {
  color: red !important;
}

.h-unset {
  height: unset;
}

.all-unset {
  all: unset;
}

.user-profile-button {
  display: grid;
  border-radius: 50%;

  padding: 0;
  place-items: center center;
  background-color: var(--bedag-green-100) !important;
}

.user-profile-letter {
  color: #fff;
  font-weight: 600;
}

.user-profile-button--small {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.4rem;
}

.user-profile-button--large {
  width: 8.125rem;
  height: 8.125rem;
  font-size: 4.55rem;
}

.table thead th {
  background-color: #f2f4f5;
}

.title-divider {
  border-bottom: 1px solid #6c757d;
  padding-bottom: 5px;
  margin-bottom: 1rem;
}

[class^='btn-outline-']:disabled, [class*=' btn-outline-']:disabled {
  border-color: transparent;
}

.btn-group.ng-invalid.ng-touched > .btn {
  border-color: var(--bs-red) !important;
}
